import React, { Component } from 'react'
import LazyLoad from 'react-lazyload'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { isLoaded } from 'react-redux-firebase'
import Cleave from 'cleave.js/react'
import 'cleave.js/dist/addons/cleave-phone.kr'
import Map from './Map'
import PhoneNumber from 'awesome-phonenumber'
import PublicIP from 'public-ip'
import * as Sentry from '@sentry/browser'
import store from '../../redux/store'
import DayPicker from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'
import 'moment/locale/ko'
import noShopImg from '../../image/quote/noShopImg170x170.svg'
import coupon from '../../image/quote/coupon.svg'
import MockAdapter from 'axios-mock-adapter'
import axios from 'axios'

import {
  updateQuote,
  saveQuote,
  searchShope,
  sendGA,
  sendNabusScript,
  consultingReqToSlack,
  alertToSlack,
  setGeolocationStatus,
  getStoreNearLocation,
  setSelectedStoreIndex,
  setSelectedStore,
  couponCodeToSlack,
} from '../../redux/actions'
import grey from '../../image/home/grey.png'
import search_icon from '../../image/quote/search_icon.png'
import quote_request from '../../image/quote/thumbsup_icon.png'
import clear_button from '../../image/quote/clear_button.png'
import no_result from '../../image/quote/no_result_x2.png'
import { zendeskOpen, getStatus, updatePath } from '../../utils/zendesk'
import { geolocation, reverseGeocoding } from '../../utils/megcenter'
import loadingBarSrc from '../../image/GIF/progress_bar.gif'
import $ from 'jquery'

const localList = {
  '전체 지역': '',
  서울특별시: '서울',
  경기도: '경기',
  강원도: '강원',
  경상남도: '경남',
  경상북도: ['경북', '경기도'],
  광주광역시: ['광주', '경기도'],
  대구광역시: ['대구', '해운대구'],
  대전광역시: '대전',
  부산광역시: '부산',
  세종특별자치시: '세종',
  울산광역시: '울산',
  인천광역시: '인천',
  전라남도: '전남',
  전라북도: '전북',
  제주특별자치도: '제주',
  충청남도: '충남',
  충청북도: '충북',
}

const Month = { Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12 }

class Shope extends Component {
  constructor(props) {
    super(props)
    this.state = {
      quoteId: props.match.params.id,
      match: props.match.params.test,
      query: this.props.query,
      map: {},
      selectedImage: null,
      selectedImageIndex: 0,
      show_left_arrow: false,
      show_right_arrow: true,
      phoneIsValid: false,
      phoneNum: '',
      checked: false,
      ImageNormalState: {
        selectedImageState: false,
        storeImageStateList: [],
      },
      isMobile: false,
      selectedLocalButton: '',
      mobileSelectLocalBoxState: true,
      queryHistory: '',
      geoObject: {},
      geoLocationStatus: this.props.geoLocationStatus,
      validAddress: false,
      loading_bar_flag: false,
      defaultLocation: { lat: 37.5135, long: 127.0315, address: '서울특별시' },
      couponCode: '',
      homePath: '',
      agentStatusOffline: false,
      iptargeting: false,
      online_sale_click: false,
      selectedDay: undefined,
    }

    this.onSelectShope = this.onSelectShope.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
    this.onChangePhone = this.onChangePhone.bind(this)
    this.onConfirm = this.onConfirm.bind(this)
    this.map = React.createRef()
    this.handleDayClick = this.handleDayClick.bind(this)
  }

  handleImageLoading(type, index) {
    const { ImageNormalState } = this.state
    var { storeImageStateList } = ImageNormalState

    if (type === 'selectedImageState') {
      this.setState({
        ImageNormalState: {
          ...ImageNormalState,
          [type]: true,
        },
      })
    } else {
      storeImageStateList[index] = 1
      this.setState({
        ImageNormalState: {
          ...ImageNormalState,
          [type]: storeImageStateList,
        },
      })
    }
  }

  zendeskShow() {
    // window.$zopim.livechat.button.show()
    alertToSlack()
    window.zE('webWidget', 'show')
    this.chatStartZendesk()
    // sendNabusScript(this.state.quoteId, "E4494C03")
  }

  addComma(price) {
    price = JSON.stringify(price)

    var regexp = /\B(?=(\d{3})+(?!\d))/g
    return price.toString().replace(regexp, ',')
  }

  async chatStartZendesk() {
    const { quote } = this.props
    const packageInfo = quote.quote.packages
    // const customerInfo = quote.quote.info
    const customerCar = quote.quote.car
    const customerBudget = quote.quote.budget
    const customerProductStatus = quote.quote.product_status
    const customerSelectStore = quote.quote.store_info.name ? quote.quote.store_info.name : '선택매장없음'
    const onlineSaleDate = quote.quote.online_sale_date ? quote.quote.online_sale_date : null
    var startMessage

    //information from packages
    if (Object.keys(packageInfo).length !== 0) {
      startMessage =
        '[상담 내용 요약] \n' +
        '패키지 : ' +
        packageInfo.product_package +
        '\n' +
        '• 시공 제품 : \n' +
        '  1) 전면창 - ' +
        (packageInfo.product_name[0] ? packageInfo.product_name[0] : '선택제품없음') +
        '\n' +
        '  2) 측후면 - ' +
        (packageInfo.product_name[1] ? packageInfo.product_name[1] : '선택제품없음') +
        '\n' +
        '• 최근 선택한 매장 : ' +
        customerSelectStore +
        (onlineSaleDate ? '\n • 시공 희망 날짜 : ' + onlineSaleDate : '')
    } else {
      startMessage =
        '[상담 내용 요약] \n' +
        '• 시공 차량 : ' +
        customerCar.origin +
        ' ・ ' +
        customerCar.maker +
        ' ・ ' +
        customerCar.model +
        '\n' +
        (customerBudget.isSelected
          ? '• 고객 예산 : ' + customerBudget.value + '만원대'
          : '• 시공 제품 : \n' +
            '  1) 전면 - ' +
            (customerProductStatus[0].lineup ? customerProductStatus[0].lineup : '선택제품없음') +
            '\n' +
            '  2) 측후면 - ' +
            (customerProductStatus[1].lineup ? customerProductStatus[1].lineup : '선택제품없음') +
            '\n' +
            '  3) 썬루프 - ' +
            (customerProductStatus[8].lineup ? customerProductStatus[8].lineup : '선택제품없음')) +
        '\n' +
        '• 최근 선택한 매장 : ' +
        customerSelectStore +
        (onlineSaleDate ? '\n• 시공 희망 날짜 : ' + onlineSaleDate : '')
    }

    updatePath('store')

    window.zE('webWidget', 'chat:send', startMessage, function(err) {})

    setTimeout(() => zendeskOpen(), 500)
  }

  consultingReqToSlack() {
    const { quote } = this.props
    const customerInfo = quote.quote.info
    const customerCar = quote.quote.car
    const customerProductStatus = quote.quote.product_status
    const customerSelectStore = quote.quote.store_info.name ? quote.quote.store_info.name : '선택매장없음'
    const customerPhone = quote.quote.phone
    const packageInfo = quote.quote.packages
    const customerBudget = quote.quote.budget

    var consultingInfo

    if (Object.keys(packageInfo).length !== 0) {
      consultingInfo = {
        packageName: packageInfo.product_package,
        productStatus: packageInfo.product_name,
        price: packageInfo.product_price,
        store: customerSelectStore,
        phone: customerPhone,
      }
    } else if (customerBudget.value !== 0) {
      consultingInfo = {
        car: customerCar,
        price: customerInfo.sum_price,
        budget: customerBudget.value,
        store: customerSelectStore,
        phone: customerPhone,
      }
    } else {
      consultingInfo = {
        car: customerCar,
        productStatus: customerProductStatus,
        store: customerSelectStore,
        price: quote.quote.info.sum_price,
        phone: customerPhone,
      }
    }
    if (quote.quote.online_sale_date) {
      consultingInfo = {
        ...consultingInfo,
        store: null,
        reservation_date: quote.quote.online_sale_date,
      }
    }
    consultingReqToSlack(consultingInfo)
  }

  couponCodeToSlack(data) {
    couponCodeToSlack(data)
  }

  phoneIsValided(event) {
    const _phone = new PhoneNumber(event.target.rawValue, 'KR')
    this.setState({
      phoneIsValid: _phone.isMobile(),
    })
  }

  isMobile() {
    var userAgent = navigator.userAgent

    if (
      userAgent.match(
        /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i,
      ) != null ||
      userAgent.match(/LG|SAMSUNG|Samsung/) != null
    ) {
      return true
    } else {
      return false
    }
  }

  initImageState() {
    const { shopes } = this.props
    const { ImageNormalState } = this.state

    var arr = []

    for (var i = 0; i < shopes.length; i++) {
      arr[i] = 0
    }

    this.setState({
      ImageNormalState: {
        ...ImageNormalState,
        storeImageStateList: arr,
      },
    })
  }

  async ipTargeting() {
    const publicIp = PublicIP
    const { searchShope, getStoreNearLocation } = this.props
    const { defaultLocation } = this.state

    let geoLocationResult = ''

    // default address 서울특별시
    let address = '서울특별시'
    let validAddress = false
    let geoObject
    await geolocation({ ip: await publicIp.v4() })
      .then(result => {
        console.log(result)
        geoLocationResult = result
      })
      .catch(err => {
        Sentry.captureException(err)
      })
    if (geoLocationResult !== '') {
      geoObject = JSON.parse(geoLocationResult)
      this.setState({ geoObject: geoObject.geoLocation })

      // geoObject에 주소 (r1,r2,r3)와 위도(lat) 경도(long)가 있다.
      geoObject = this.state.geoObject

      if (localList[geoObject.r1]) {
        address = geoObject.r1
        // if address within south Korea, validAddress = true
        validAddress = true
        this.setState({
          validAddress: validAddress,
        })
        getStoreNearLocation(geoObject.lat, geoObject.long, address)
      } else {
        getStoreNearLocation(defaultLocation.lat, defaultLocation.long, defaultLocation.address)
      }
    }

    if (!validAddress) {
      searchShope('', localList[address])
    }
    this.setState({
      selectedLocalButton: address,
      query: '',
      isMobile: this.isMobile(),
    })
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
    this.initImageState()
  }

  async geoCoding(lat, long) {
    const { defaultLocation } = this.state
    const { searchShope, getStoreNearLocation } = this.props

    // default address 서울특별시
    let address = '서울특별시'
    let validAddress = false
    let geoLocationResult
    await reverseGeocoding({ lat: lat, long: long })
      .then(result => {
        console.log(result)
        geoLocationResult = result
      })
      .catch(err => {
        Sentry.captureException(err)
      })

    if (geoLocationResult !== '') {
      let geoObject = JSON.parse(geoLocationResult)
      this.setState({
        geoObject: {
          // region_type B: 법정동, H: 행정동
          address: geoObject.documents[0].region_1depth_name,
          lat: lat,
          long: long,
        },
      })

      // geoObject에 주소(address)와 위도(lat) 경도(long)가 있다.
      geoObject = this.state.geoObject

      if (localList[geoObject.address]) {
        address = geoObject.address
        // if address within south Korea, validAddress = true
        validAddress = true
        this.setState({
          validAddress: validAddress,
        })
        getStoreNearLocation(lat, long, address)
      } else {
        getStoreNearLocation(defaultLocation.lat, defaultLocation.long, defaultLocation.address)
      }
    }

    if (!validAddress) {
      searchShope('', localList[address])
    }
    this.setState({
      selectedLocalButton: address,
      query: '',
      isMobile: this.isMobile(),
      iptargeting: true,
    })
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
    this.initImageState()
  }
  async componentDidMount() {
    const { homePath } = store.getState().home
    this.setState({
      homePath: homePath,
    })
    await this.applyGeoLocation()
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.vertexStores !== this.props.vertexStores) {
      await this.applyGeoLocation()
    }
  }

  async applyGeoLocation() {
    const { geoLocationStatus } = this.state
    if (!geoLocationStatus) {
      // initially start iptargeting
      await this.ipTargeting()
    }

    // if browser support geolocation, request currentPosition
    await new Promise(function(resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject)
    })
      .then(async position => {
        this.props.setGeolocationStatus(true)

        const lat = position.coords.latitude
        const long = position.coords.longitude

        // if there is geolocation data, start geocoding
        this.geoCoding(lat, long)
      })
      .catch(err => {
        setGeolocationStatus(false)
        this.setState({
          iptargeting: true,
        })
        console.error(err.message)
      })
    if (this.props.location.search === '?test=true') {
      var mock = new MockAdapter(axios)
      mock.onPost('https://api.riderdash.com/send/marketplace/consulting_req_to_send').reply(200, {
        users: [{ id: 'success!', name: 'Mock is okay' }],
      })
    }
  }

  onUpdate(updatedData) {
    this.setState({
      ...updatedData,
    })
  }

  onSelectShope(index) {
    const { isMobile } = this.state
    const { latitude, longitude } = this.props.shopes[index]

    if (!isMobile) {
      var moveLatLon = new window.daum.maps.LatLng(latitude, longitude)
      this.state.map.setLevel(5)
      this.state.map.panTo(moveLatLon)
      this.state.map.setCenter(moveLatLon)
      var shopResultUnitElement = document.querySelectorAll('div.shop_result_unit')
      if (shopResultUnitElement.length > 2) {
        var offset = shopResultUnitElement[index].offsetTop
        window.scrollTo({ top: offset, behavior: 'smooth' })
      }
      this.props.setSelectedStoreIndex(index)
    }
    this.initScroll()
    this.props.setSelectedStore(index)
  }

  onKeyPress(e) {
    const { searchShope } = this.props
    const { query } = this.state

    if (e.key === 'Enter') {
      this.setState({
        selectedLocalButton: '전체 지역',
        mobileSelectLocalBoxState: false,
        searchHistory: query,
      })
      this.props.setSelectedStore(null)
      searchShope(query, '', this.props.vertexStores)
      sendGA({ category: 'After Quote', action: 'Search Shops' })
      this.initImageState()
    }
  }

  onChangePhone(event) {
    if (event.target.rawValue) {
      this.props.updateQuote({
        phone: event.target.rawValue,
      })
    }
    this.setState({
      phoneNum: event.target.rawValue,
    })
  }

  onConfirm() {
    sendNabusScript(this.state.quoteId, 'E4494C02')
  }

  handleScroll(e) {
    this.setState({ show_left_arrow: true, show_right_arrow: true })
    if (e.target.scrollLeft === 0) {
      this.setState({ show_left_arrow: false })
    }
    if (e.target.scrollLeft + e.target.offsetWidth === e.target.scrollWidth) {
      this.setState({ show_right_arrow: false })
    }
  }

  initScroll() {
    this.scroll_div.scrollLeft = 0
  }

  handleMoblieSelectLocalBox() {
    const { mobileSelectLocalBoxState } = this.state
    this.setState({
      mobileSelectLocalBoxState: !mobileSelectLocalBoxState,
    })
  }

  handleLocalButton(local, index) {
    window.scrollTo(0, 0)

    const { searchShope } = this.props
    const { isMobile } = this.state
    this.setState({
      query: '',
      selectedLocalButton: local,
      mobileSelectLocalBoxState: false,
    })
    searchShope('', localList[local], local)

    if (!isMobile) {
      var els = document.querySelectorAll('#local_button')
      els[index].classList.toggle('selected_button', true)
    }

    this.initImageState()
  }

  couponGenerator(length) {
    var result = ''
    var characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  getCouponValidity(dateToGo) {
    let date = new Date()
    const dayOfMonth = date.getDate()
    date.setDate(dayOfMonth + dateToGo)

    const result = `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} `
    return result
  }

  handleDayClick(day, modifiers = {}) {
    if (modifiers.disabled) {
      return
    }
    this.setState({
      selectedDay: modifiers.selected ? undefined : day,
    })
  }

  render() {
    const { searchShope } = this.props

    const {
      ImageNormalState,
      isMobile,
      query,
      selectedLocalButton,
      searchHistory,
      homePath,
      agentStatusOffline,
    } = this.state
    const { storeImageStateList } = ImageNormalState

    const store_info = this.props.shope.shopes[this.props.selectedStore]

    let homePathChecker = 'original'

    const today = new Date()

    if (homePath === '/cars-coupon') {
      homePathChecker = 'new'
    }

    function formatDate(d, locale) {
      return `${d.getFullYear()}년 ${d.getMonth() + 1}월`
    }
    return (
      <div>
        {this.state.loading_bar_flag ? (
          <div className="loading_bar_background">
            <div className={isMobile ? 'loading_bar_container_mobile' : 'loading_bar_container_desktop'}>
              <img alt="" src={loadingBarSrc} className={isMobile ? 'loading_bar_mobile' : 'loading_bar_desktop'}></img>
              <div className={isMobile ? 'loading_bar_text_mobile' : 'loading_bar_text_desktop'}>
                해당 차량과 동일한 전국 데이터를 불러오는 중...
              </div>
            </div>
          </div>
        ) : null}
        {this.state.online_sale_click ? (
          <div className="background">
            <div className={!isMobile ? 'datepicker_container' : 'datepicker_container_Mobile'}>
              <div className="datepicker_text">시공을 희망하는 날짜를 선택해주세요</div>
              <div className="datepicker_line" />
              <DayPicker
                showOutsideDays
                localeUtils={{ ...MomentLocaleUtils, formatDate }}
                locale="ko"
                disabledDays={{ before: today }}
                onDayClick={this.handleDayClick}
                selectedDays={this.state.selectedDay}
              />
              <div className="datepicker_line" />
              <div>
                <div
                  className="btn btn_line_red btn_sm btn-block"
                  onClick={() => {
                    this.setState({ online_sale_click: false })
                  }}
                >
                  닫기
                </div>
                <button
                  className="btn btn_red btn_sm btn-block"
                  onClick={async () => {
                    const array = this.state.selectedDay.toString().split(' ')
                    const days = array[3] + '-' + Month[array[1]] + '-' + array[2]
                    await this.props.updateQuote({
                      online_sale_date: days,
                    })
                    this.setState({
                      loading_bar_flag: true,
                      online_sale_click: false,
                    })
                    setTimeout(() => {
                      this.setState({
                        loading_bar_flag: false,
                      })
                      if (getStatus() === 'online') {
                        this.zendeskShow()
                        this.setState({
                          agentStatusOffline: false,
                          online_sale_click: false,
                        })
                        sendGA({
                          category: 'After Quote',
                          action: 'Start Online Reservation Chat',
                        })
                      } else {
                        this.setState({
                          phoneNum: '',
                          phoneIsValid: false,
                          checked: false,
                          agentStatusOffline: true,
                        })
                        $('#e2e_consultReq_btn_0').click()
                      }

                      this.props.history.push(`/consulting/chat/${this.state.quoteId}`)
                    }, 4000)
                  }}
                >
                  특가 조회하기
                </button>
              </div>
            </div>
          </div>
        ) : null}
        {!isLoaded(this.props.quotes && this.props.quote.quote) ? null : (
          <div className="container shop_container">
            <div className="row">
              <div className="col-12 col-lg-5" style={{ zIndex: 3 }}>
                <div className="shop_search" id="e2e_shop_search">
                  {this.state.query.length > 0 && (
                    <button
                      className="clear_button"
                      onClick={() => {
                        this.setState({ query: '' })
                        searchShope('', localList[selectedLocalButton])
                        this.initImageState()
                        this.props.setSelectedStore(null)
                      }}
                    >
                      <img src={clear_button} alt="" />
                    </button>
                  )}
                  <button
                    id="e2e_search_button"
                    className="search_button"
                    onClick={() => {
                      sendGA({ category: 'After Quote', action: 'Search Shops' })
                      this.setState({
                        selectedLocalButton: '전체 지역',
                        mobileSelectLocalBoxState: false,
                        searchHistory: query,
                      })
                      this.props.setSelectedStore(null)
                      searchShope(query, '')
                      this.initImageState()
                    }}
                  >
                    검색
                  </button>
                  <input
                    id="e2e_search_bar"
                    className="search_bar"
                    type="text"
                    placeholder="매장명, 자치구, 도로명 검색"
                    value={this.state.query}
                    onChange={e => {
                      this.setState({ query: e.target.value })
                    }}
                    onKeyPress={this.onKeyPress}
                  />
                  <img src={search_icon} alt="" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-7 order-lg-12">
                {!isMobile ? (
                  <div className={this.props.shopes.length ? 'sticky-top' : 'd-none d-lg-block'}>
                    <div className="btn_group">
                      {Object.keys(localList).map((local, index) => {
                        return (
                          <button
                            className={local === this.state.selectedLocalButton ? 'selected_button' : null}
                            key={index}
                            id="local_button"
                            onClick={() => {
                              this.handleLocalButton(local, index)
                              this.props.setSelectedStore(null)
                            }}
                          >
                            {local}
                          </button>
                        )
                      })}
                    </div>
                    <div className="shop_map">
                      <div id="wrap" className="section" data-iptargeting={this.state.iptargeting}>
                        <Map
                          ref={this.map}
                          Locations={this.props.shopes}
                          onUpdate={this.onUpdate.bind(this)}
                          onSelectShope={this.onSelectShope}
                          currentStoreIndex={this.props.selectedStore}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {isMobile ? (
                  <div>
                    <div className="selected_local_box" onClick={() => this.handleMoblieSelectLocalBox()}>
                      {selectedLocalButton}
                      <button className="arrow_down" />
                    </div>
                    {this.state.mobileSelectLocalBoxState ? (
                      <div className="btn_group_m">
                        {Object.keys(localList).map((local, index) => {
                          return (
                            <button
                              className={local === this.state.selectedLocalButton ? 'selected_button' : null}
                              key={index}
                              onClick={() => this.handleLocalButton(local)}
                            >
                              {local}
                            </button>
                          )
                        })}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>

              <div className="col-12 col-lg-5 order-lg-1" style={{ zIndex: 2 }}>
                <div className="shop_result">
                  <div className="num_of_shops">
                    <div>
                      검색된 매장: {Object.keys(this.props.shope.shopes).length} /{' '}
                      {Object.keys(this.props.vertexStores).length}개
                    </div>
                  </div>
                  {this.props.shopes.length ? (
                    this.props.shopes.map((shope, i) => {
                      return (
                        <div className={'shop_result_unit'} key={i}>
                          <div
                            className="shop_store_information"
                            onClick={() => {
                              this.props.updateQuote({
                                // store_info: shope
                                store_info: store_info,
                              })
                              this.onSelectShope(i)
                              this.setState({
                                ImageNormalState: {
                                  ...ImageNormalState,
                                  selectedImageState: false,
                                },
                              })
                            }}
                          >
                            <div className={this.props.selectedStore === i && !isMobile ? 'selected_bar' : ''} />
                            <div className="representative_images" ref={ref => (this.scroll_div = ref)}>
                              <div key={i} className="img_section">
                                <LazyLoad placeholder={<img src={grey} alt="" />} key={i}>
                                  <img
                                    key={i}
                                    src={
                                      storeImageStateList[i]
                                        ? `https://storage.riderdash.com/assets/${shope.id}.jpg`
                                        : grey
                                    }
                                    alt=""
                                    onLoad={() => this.handleImageLoading('storeImageStateList', i)}
                                    onError={e => {
                                      this.onError = null
                                      e.target.src = noShopImg
                                      return true
                                    }}
                                  />
                                </LazyLoad>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <p className="store">{shope.name}</p>
                                <p className="address">{shope.address}</p>
                                <p className="distance">
                                  {'현재 위치 기준  |  약 ' + (shope.dist / 1000).toFixed(1) + 'km'}
                                </p>
                                <p className="contact_time">{shope.contact_time}</p>
                                <p className="contact_number">{shope.number}</p>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 clearfix sides_container">
                            <div style={{ width: '100%' }}>
                              {shope.id.endsWith('3') || shope.id.endsWith('5') ? (
                                <div className="on_sale_store">온라인 예약 할인중!</div>
                              ) : null}
                            </div>
                            <button
                              type="button"
                              className="btn btn_line_red btn_sm btn-block"
                              name="consulting"
                              data-toggle="modal"
                              id={'e2e_consultReq_btn_' + i}
                              onClick={() => {
                                this.props.updateQuote({
                                  store_info: shope,
                                })
                                this.onSelectShope(i)
                                this.setState({
                                  phoneNum: '',
                                  phoneIsValid: false,
                                  checked: false,
                                })
                                if (homePathChecker === 'original') {
                                  sendGA({
                                    category: 'After Quote',
                                    action: 'Start Consulting',
                                  })
                                  if (shope.id.endsWith('3') || shope.id.endsWith('5')) {
                                    sendGA({
                                      category: 'After Quote',
                                      action: 'Start On Sale Consulting',
                                    })
                                  }
                                } else {
                                  sendGA({
                                    category: 'After Quote',
                                    action: 'Download Coupon',
                                  })
                                  if (shope.id.endsWith('3') || shope.id.endsWith('5')) {
                                    sendGA({
                                      category: 'After Quote',
                                      action: 'Download On Sale Coupon',
                                    })
                                  }
                                }

                                this.props.history.push(`/consulting/start_consulting/${this.state.quoteId}`)
                              }}
                              data-target="#quote_apply_modal"
                            >
                              {homePathChecker === 'original' ? '상담신청' : '할인 쿠폰 받기'}
                            </button>
                            <button
                              type="button"
                              id="e2e_consulting_button"
                              className="btn btn_line_red btn_sm btn_block consulting_button"
                              data-toggle="modal"
                              data-target="#not_support_yet"
                              data-status={getStatus()}
                              onClick={async () => {
                                await this.props.updateQuote({
                                  store_info: shope,
                                })
                                this.onSelectShope(i)
                                this.setState({
                                  loading_bar_flag: true,
                                })
                                setTimeout(() => {
                                  this.setState({
                                    loading_bar_flag: false,
                                  })

                                  if (getStatus() === 'online') {
                                    this.zendeskShow()
                                    this.setState({
                                      agentStatusOffline: false,
                                    })
                                  } else {
                                    $('#e2e_consultReq_btn_' + i).click()
                                    this.setState({
                                      agentStatusOffline: true,
                                    })
                                  }
                                  sendGA({
                                    category: 'After Quote',
                                    action: 'Start Quote Chat',
                                  })
                                  if (shope.id.endsWith('3') || shope.id.endsWith('5')) {
                                    sendGA({
                                      category: 'After Quote',
                                      action: 'Start On Sale Quote Chat',
                                    })
                                  }
                                  this.props.history.push(`/consulting/chat/${this.state.quoteId}`)
                                }, 4000)
                              }}
                            >
                              실시간 견적
                            </button>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className="shop_noresult">
                      <img src={no_result} alt="" />
                      <div className="shop_noresult_title">검색 결과 없음</div>
                      <div className="shop_noresult_text">
                        검색하신 '{searchHistory}'에 대한 결과가 없습니다.
                        <br />
                        다른 검색어로 다시 시도하시거나 문의주시기 바랍니다.
                      </div>
                      <div
                        className="btn btn_red btn_sm btn_block noresult_button"
                        data-toggle="modal"
                        data-target="#not_support_yet"
                      >
                        1:1 채팅상담
                      </div>

                      <div
                        className="btn btn_black btn_sm btn_block noresult_button"
                        data-toggle="modal"
                        data-target="#not_support_yet"
                        onClick={() => {
                          searchShope('', localList['전체 지역'])
                          this.setState({
                            selectedLocalButton: '전체 지역',
                            query: '',
                          })
                          this.props.setSelectedStore(null)
                        }}
                      >
                        전체 매장 보기
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="modal fade" id="quote_apply_modal" tabIndex="-1" role="dialog" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content quote_apply_modal_content">
                  <div className="modal-body">
                    {agentStatusOffline ? (
                      <p className="apply_title" id="agentOffline">
                        죄송합니다.
                        <br />
                        현재 연결 가능한 상담원이 없습니다.
                        <br />
                        <br />
                        작성하신 견적서를 바탕으로 최대한 빠른 시일 내에 연락 드리겠습니다.
                      </p>
                    ) : homePathChecker === 'original' ? (
                      <p className="apply_title">
                        작성하신 견적서를 바탕으로 당일 혹은 익일에 저희 상담원이 전화를 드립니다.
                      </p>
                    ) : (
                      <p className="apply_title">
                        썬팅 시공 할인 쿠폰을 발급 받으실 <br /> 휴대폰 번호를 입력해주세요.
                      </p>
                    )}
                    <Cleave
                      className="mb-2"
                      type="tel"
                      placeholder="010-1234-5678"
                      value={this.state.phoneNum}
                      onChange={e => {
                        this.phoneIsValided(e)
                        this.onChangePhone(e)
                      }}
                      options={{
                        phone: true,
                        phoneRegionCode: 'KR',
                        delimiter: '-',
                      }}
                    />
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn_transparent btn-block w-50 float-left text_red"
                      data-dismiss="modal"
                      onClick={() =>
                        sendGA({
                          category: 'After Quote',
                          action: 'Cancel Request',
                        })
                      }
                    >
                      취소
                    </button>
                    <button
                      type="button"
                      className="btn btn_red btn-block w-50 float-left"
                      id="e2e_consult"
                      data-dismiss="modal"
                      disabled={!this.state.phoneIsValid}
                      onClick={() => {
                        if (homePathChecker === 'original' || agentStatusOffline) {
                          this.consultingReqToSlack()
                        } else {
                          const couponCode = this.couponGenerator(8)
                          const expiredDate = this.getCouponValidity(7)
                          this.setState({
                            couponCode: couponCode,
                          })
                          this.couponCodeToSlack({
                            phone: this.state.phoneNum,
                            couponCode: couponCode,
                            date: expiredDate,
                          })
                        }

                        if (agentStatusOffline) {
                          sendGA({
                            category: 'After Quote',
                            action: 'Complete Request When Agent Offline',
                          })
                          this.props.history.push(`/consulting/complete_request_offline/${this.state.quoteId}`)
                        } else {
                          sendGA({
                            category: 'After Quote',
                            action: 'Complete Request',
                          })
                          this.props.history.push(`/consulting/complete_request/${this.state.quoteId}`)
                        }

                        this.onConfirm()
                      }}
                      data-toggle="modal"
                      data-target="#after_quote_apply_modal"
                    >
                      확인
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="after_quote_apply_modal" tabIndex="-1" role="dialog" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content after_quote_apply_modal_content">
                  {agentStatusOffline ? (
                    <div className="modal-body">
                      <img src={quote_request} alt="" />
                      <p className="apply_title">견적상담 요청이 완료되었습니다!</p>
                    </div>
                  ) : homePathChecker === 'original' ? (
                    <div className="modal-body">
                      <img src={quote_request} alt="" />
                      <p className="apply_title">
                        견적상담 요청이 완료되었습니다!
                        <br />
                        24시간 내에 회신드리겠습니다.
                      </p>
                    </div>
                  ) : (
                    <div className="modal-body-coupon">
                      <div className="coupon-section">
                        <div className="coupon_title">할인 쿠폰 발급 완료!</div>
                        <div className="coupon_message">
                          발급받으신 쿠폰 번호를 <br />
                          '실시간 견적'에서 입력해주세요.
                        </div>
                        <div className="coupon_code">{this.state.couponCode}</div>
                        <img className="coupon-image" src={coupon} alt="" />
                      </div>
                    </div>
                  )}

                  <div className="modal-footer">
                    <button type="button" className="btn btn_red btn-block w-50" data-dismiss="modal">
                      닫기
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isLoaded(this.props.quotes && this.props.quote.quote) ? null : !isMobile ? (
          <div className="online_sale_banner">
            <div className="banner_text">
              <div className="banner_title">사이트 런칭 기념 이벤트!</div>
              <div className="banner_content">
                온라인으로 시공 예약하고 일반 시공가보다 할인된 특가로 시공 할인 받으세요!
                <div className="tape" />
              </div>
            </div>
            <div
              className="btn btn_red btn_sm btn-block"
              onClick={() => {
                this.setState({ online_sale_click: true })
              }}
            >
              온라인 특가 확인
            </div>
          </div>
        ) : (
          <div className="online_sale_banner_Mobile">
            <div className="banner_text">
              <div className="banner_content_Mobile">
                온라인으로 시공 예약하고 <br /> 특가로 시공 할인 받으세요!
                <div className="tape" />
              </div>
            </div>
            <div
              className="btn btn_red btn_sm btn-block"
              onClick={() => {
                this.setState({ online_sale_click: true })
              }}
            >
              온라인 특가 확인
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...state,
    quote: state.quote,
    quotes: state.firestore.data && state.firestore.data.quotes,
    shopes: state.shope.shopes,
    query: state.shope.query,
    geoLocationStatus: state.shope.allowGeolocation,
    selectedStore: state.shope.selectedStore,
    vertexStores: state.shope.vertexStores,
  }
}

export default compose(
  firestoreConnect(props => [{ collection: 'quotes', doc: props.match.params.id }]),
  connect(mapStateToProps, {
    updateQuote,
    saveQuote,
    searchShope,
    consultingReqToSlack,
    setGeolocationStatus,
    getStoreNearLocation,
    setSelectedStoreIndex,
    setSelectedStore,
  }),
)(Shope)
