import React, { Component } from 'react'

import info_1 from '../../image/product/info_1.jpg'
import info_icon_1 from '../../image/product/info_icon_1.png'
import info_icon_2 from '../../image/product/info_icon_2.png'
import info_icon_3 from '../../image/product/info_icon_3.png'
import info_clear_1 from '../../image/product/info_clear_1.jpg'
import info_clear_2 from '../../image/product/info_clear_2.png'
import info_clear_3 from '../../image/product/info_clear_3.jpg'
import info_clear_4 from '../../image/product/info_clear_4.jpg'
import moire_revision from '../../image/product/moire_revision.jpg'
import moire_og from '../../image/product/moire_og.jpg'

import high from '../../image/product/high.png'
import mid from '../../image/product/mid.png'
import low from '../../image/product/low.png'

import info_block_1 from '../../image/product/info_block_1.jpg'
import info_block_2 from '../../image/product/info_block_2.png'
import info_wave_1 from '../../image/product/info_wave_1.jpg'

class Product extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <div className="container banner_container">
          <div className="row">
            <div className="col-12 col-md-6 order-md-12 px-0">
              <img className="d-block w-100" src={info_1} alt="" />
            </div>
            <div className="col-12 col-md-6 order-md-1">
              <div className="d-table w-100 h-100">
                <div className="d-table-cell align-middle">
                  <p className="headline">글로벌 점유율 1등</p>
                  <p className="sub">
                    루마는 자동차 윈도우 틴팅 분야에서 오랜 노하우, 기술, 제품력을 바탕으로
                    <br className="d-none d-md-inline" />
                    글로벌 점유율 1등을 차지하고 있는 검증된 브랜드입니다
                  </p>
                  <div className="info_icons d-xl-block d-none">
                    <div className="row">
                      <div className="col-4">
                        <div className="info_icon">
                          <img src={info_icon_1} alt="" />
                          <p className="icon_title">선명도</p>
                          <p className="icon_sub">
                            주행에 방해가 되는
                            <br />
                            내부 난반사 및 무아레
                            <br />
                            간섭 현상이 없음
                          </p>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="info_icon">
                          <img src={info_icon_2} alt="" />
                          <p className="icon_title">열차단</p>
                          <p className="icon_sub">
                            국제공인 표준인 TSER
                            <br />
                            기준으로 최고 수준의
                            <br />
                            열차단을 제공
                          </p>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="info_icon">
                          <img src={info_icon_3} alt="" />
                          <p className="icon_title">전파수신</p>
                          <p className="icon_sub">
                            핸드폰, GPS, 하이패스,
                            <br />
                            아파트 차단기 등<br />
                            전파 간섭 거의 없음
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row d-xl-none mt-5">
            <div className="col-12">
              <div className="info_icon">
                <img src={info_icon_1} alt="" />
                <p className="icon_title">선명도</p>
                <p className="icon_sub">
                  주행에 방해가 되는 내부 난반사 및<br />
                  무아레 간섭 현상이 없음
                </p>
              </div>
            </div>
            <div className="col-12">
              <div className="info_icon">
                <img src={info_icon_2} alt="" />
                <p className="icon_title">열차단</p>
                <p className="icon_sub">
                  국제공인 표준인 TSER 기준으로
                  <br />
                  최고 수준의 열차단을 제공
                </p>
              </div>
            </div>
            <div className="col-12">
              <div className="info_icon">
                <img src={info_icon_3} alt="" />
                <p className="icon_title">전파수신</p>
                <p className="icon_sub">
                  핸드폰, GPS, 하이패스, 아파트 차단기 등<br />
                  전파 간섭 거의 없음
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container info_tab_container my-150">
          <ul className="nav nav-tabs row justify-content-center mx-0" id="myTab" role="tablist">
            <li className="nav-item col-4 col-md-2">
              <a
                className="nav-link active"
                id="infoclear-tab"
                data-toggle="tab"
                href="#infoclear"
                role="tab"
                aria-controls="infoclear"
                aria-selected="true"
              >
                선명도
              </a>
            </li>
            <li className="nav-item col-4 col-md-2">
              <a
                className="nav-link"
                id="infoblock-tab"
                data-toggle="tab"
                href="#infoblock"
                role="tab"
                aria-controls="infoblock"
                aria-selected="false"
              >
                열차단
              </a>
            </li>
            <li className="nav-item col-4 col-md-2">
              <a
                className="nav-link"
                id="infowave-tab"
                data-toggle="tab"
                href="#infowave"
                role="tab"
                aria-controls="infowave"
                aria-selected="false"
              >
                전파수신
              </a>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="infoclear" role="tabpanel" aria-labelledby="infoclear-tab">
              <div className="row">
                <div className="col-12 col-md-6 px-0">
                  <img className="d-block w-100" src={info_clear_1} alt="" />
                </div>
                <div className="col-12 col-md-6">
                  <div className="d-table w-100 h-100">
                    <div className="d-table-cell align-middle">
                      <p className="tab_title">
                        퍼펙트 드라이빙을 위한
                        <br />
                        최고의 선명도
                      </p>
                      <p className="tab_sub">
                        루마 최첨단 테크놀로지는 주행에 방해 되지 않는 내부 난반사를
                        <br className="d-none d-md-inline" />
                        없앰으로서 보다 쾌적하고 넓은 시야 환경을 제공합니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <img className="d-block mx-auto w-90" src={info_clear_2} alt="" />
                </div>
                <div className="col-12 col-md-6">
                  <table className="lluma_table lluma_table_m info_table mx-auto w-90">
                    <colgroup>
                      <col width="150" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>제조사/제품</th>
                        <th>금속여부</th>
                        <th>선명도</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>루마Vertex</td>
                        <td>비금속</td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td>A사 최고가 라인</td>
                        <td>비금속</td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td>B사 최고가 라인</td>
                        <td>금속</td>
                        <td>
                          <img src={low} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td>C사 최고가 라인</td>
                        <td>비금속</td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td>D사 최고가 라인</td>
                        <td>금속</td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="lluma_table_more mx-auto w-90">
                    <p>
                      <span className="font-weight-700">VLR</span>(내부반사율) : Visible Light Reflectance
                    </p>
                    <p>
                      일반적으로 <span className="font-weight-700">금속 성분</span>이 포함된 필름은 내부 반사율이 높아
                      난반사가 발생하거나 무아레 간섭에 의해 브랜드별로 금속의 구체적인 함유량과 성분에 따라 선명도에
                      차이가 날 수 있습니다.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row justify-content-between">
                <div className="col-12 text-left">
                  <p className="info_case_title">내부 난반사</p>
                </div>
                <div className="col-6 col-md-5">
                  <img className="d-block w-100" src={info_clear_3} alt="" />
                  <p className="info_case_sub">금속성 필름 : 내부 난반사로 주간 주행에 방해</p>
                </div>
                <div className="col-6 col-md-5">
                  <img className="d-block w-100" src={info_clear_4} alt="" />
                  <p className="info_case_sub">루마Vertex : 내부 난반사 없는 선명도</p>
                </div>
              </div>

              <div className="row justify-content-between">
                <div className="col-12 mt-100 text-left">
                  <p className="info_case_title">무아레 간섭 : Moire Interference</p>
                </div>
                <div className="col-6 col-md-5">
                  <img className="d-block w-100" src={moire_revision} alt="" />
                  <p className="info_case_sub">금속성 필름 : 무아레 간섭으로 야간 주행에 방해</p>
                </div>
                <div className="col-6 col-md-5">
                  <img className="d-block w-100" src={moire_og} alt="" />
                  <p className="info_case_sub">루마Vertex : 무아레 간섭 없는 선명도</p>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="infoblock" role="tabpanel" aria-labelledby="infoblock-tab">
              <div className="row">
                <div className="col-12 col-md-6 px-0">
                  <img className="d-block w-100" src={info_block_1} alt="" />
                </div>
                <div className="col-12 col-md-6">
                  <div className="d-table w-100 h-100">
                    <div className="d-table-cell align-middle">
                      <p className="tab_title">
                        첨단 나노 융복합 기술의
                        <br />
                        탁월한 열차단율
                      </p>
                      <p className="tab_sub">
                        루마 최첨단 테크놀로지는 주행에 방해 되지 않는 내부 난반사를
                        <br className="d-none d-md-inline" />
                        없앰으로서 보다 쾌적하고 넓은 시야 환경을 제공합니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <img className="d-block mx-auto w-90" src={info_block_2} alt="" />
                </div>
                <div className="col-12 col-md-6">
                  <table className="lluma_table lluma_table_m info_table mx-auto w-90">
                    <colgroup>
                      <col width="150" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>제조사/제품</th>
                        <th>금속여부</th>
                        <th>선명도</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>루마Vertex</td>
                        <td>비금속</td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td>A사 최고가 라인</td>
                        <td>비금속</td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td>B사 최고가 라인</td>
                        <td>금속</td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td>C사 최고가 라인</td>
                        <td>비금속</td>
                        <td>
                          <img src={low} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td>D사 최고가 라인</td>
                        <td>금속</td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="lluma_table_more mx-auto w-90">
                    <p>
                      <span className="font-weight-700">TSER</span> : Total Solar Energy Rejected. 국제 윈도우 필름
                      협회(International Window Film Association)에서 국제적으로 공인받은 열차단 수치로 적외선, 자외선,
                      가시광선을 모두 포괄하여 계산함. 90% 이상의 열차단율을 홍보하는 일부 타사 제품들은 태양열의 절반을
                      차지하는 가시광선을 제외한 차단율만 표기하고 있습니다.
                    </p>
                    <p style={{ marginBottom: '10px' }}>
                      금속성 필름이 낮은 선명 및 전파수신 간섭 문제가 있음에도 불구하고 틴팅에 쓰이는 이유는 열차단 성능
                      때문입니다. 하지만 루마는 금속 성분을 첨가하지 않고도 동일한 열차단 성능을 구현한 독보적인 기술을
                      보유하고 있습니다.
                    </p>
                    <p>
                      야간 운전 및 본인 취향에 따라 가시광선투과율인 <span className="font-weight-700">VTL %</span>를
                      결정하시면 됩니다. 일반적으로 전면 <span className="font-weight-700">35 %</span> 측후면{' '}
                      <span className="font-weight-700">15 %</span>의 조합이 많이 선택되지만 야간 운전 빈도 및 개인
                      취향에 따라 투과율을 선택하시기 바랍니다.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row product_table_row">
                <div className="col-12 col-md-6">
                  <table className="lluma_table lluma_table_m product_table mx-auto w-90">
                    <colgroup>
                      <col width="150" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>VERTEX 1100</th>
                        <th>VTL</th>
                        <th>TSER</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Vertex 1100-15</td>
                        <td>15%</td>
                        <td>70%</td>
                      </tr>
                      <tr>
                        <td>Vertex 1100-30</td>
                        <td>30%</td>
                        <td>65%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-12 col-md-6">
                  <table className="lluma_table lluma_table_m product_table mx-auto w-90">
                    <colgroup>
                      <col width="150" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>VERTEX 900</th>
                        <th>VTL</th>
                        <th>TSER</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Vertex 900-5</td>
                        <td>5%</td>
                        <td>70%</td>
                      </tr>
                      <tr>
                        <td>Vertex 900-15</td>
                        <td>15%</td>
                        <td>68%</td>
                      </tr>
                      <tr>
                        <td>Vertex 900-30</td>
                        <td>30%</td>
                        <td>63%</td>
                      </tr>
                      <tr>
                        <td>Vertex 900-40</td>
                        <td>40%</td>
                        <td>59%</td>
                      </tr>
                      <tr>
                        <td>Vertex 900-50</td>
                        <td>50%</td>
                        <td>57%</td>
                      </tr>
                      <tr>
                        <td>Vertex 900-80</td>
                        <td>80%</td>
                        <td>50%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row product_table_row">
                <div className="col-12 col-md-6">
                  <table className="lluma_table lluma_table_m product_table mx-auto w-90">
                    <colgroup>
                      <col width="150" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>VERTEX 700</th>
                        <th>VTL</th>
                        <th>TSER</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Vertex 700-5</td>
                        <td>5%</td>
                        <td>67%</td>
                      </tr>
                      <tr>
                        <td>Vertex 700-15</td>
                        <td>15%</td>
                        <td>63%</td>
                      </tr>
                      <tr>
                        <td>Vertex 700-35</td>
                        <td>35%</td>
                        <td>59%</td>
                      </tr>
                      <tr>
                        <td>Vertex 700-50</td>
                        <td>50%</td>
                        <td>55%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-12 col-md-6">
                  <table className="lluma_table lluma_table_m product_table mx-auto w-90">
                    <colgroup>
                      <col width="150" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>VERTEX 650</th>
                        <th>VTL</th>
                        <th>TSER</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Vertex 650-10</td>
                        <td>10%</td>
                        <td>70%</td>
                      </tr>
                      <tr>
                        <td>Vertex 650-20</td>
                        <td>20%</td>
                        <td>65%</td>
                      </tr>
                      <tr>
                        <td>Vertex 650-35</td>
                        <td>35%</td>
                        <td>59%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row product_table_row">
                <div className="col-12 col-md-6">
                  <table className="lluma_table lluma_table_m product_table mx-auto w-90">
                    <colgroup>
                      <col width="150" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>VERTEX 600</th>
                        <th>VTL</th>
                        <th>TSER</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Vertex 600-15</td>
                        <td>5%</td>
                        <td>65%</td>
                      </tr>
                      <tr>
                        <td>Vertex 600-30</td>
                        <td>15%</td>
                        <td>59%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-12 col-md-6">
                  <table className="lluma_table lluma_table_m product_table mx-auto w-90">
                    <colgroup>
                      <col width="150" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>VERTEX 500</th>
                        <th>VTL</th>
                        <th>TSER</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Vertex 500-5</td>
                        <td>5%</td>
                        <td>64%</td>
                      </tr>
                      <tr>
                        <td>Vertex 500-15</td>
                        <td>15%</td>
                        <td>55%</td>
                      </tr>
                      <tr>
                        <td>Vertex 500-35</td>
                        <td>35%</td>
                        <td>52%</td>
                      </tr>
                      <tr>
                        <td>Vertex 500-50</td>
                        <td>50%</td>
                        <td>50%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="infowave" role="tabpanel" aria-labelledby="infowave-tab">
              <div className="row">
                <div className="col-12 col-md-6 px-0">
                  <img className="d-block w-100" src={info_wave_1} alt="" />
                </div>
                <div className="col-12 col-md-6">
                  <div className="d-table w-100 h-100">
                    <div className="d-table-cell align-middle">
                      <p className="tab_title">
                        모바일 디바이스를 위한
                        <br />
                        전파수신 지원
                      </p>
                      <p className="tab_sub">
                        루마 최첨단 테크놀로지는 주행에 방해 되지 않는 내부 난반사를
                        <br className="d-none d-md-inline" />
                        없앰으로서 보다 쾌적하고 넓은 시야 환경을 제공합니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <table className="lluma_table info_table w-100 d-none d-xl-table">
                    <colgroup>
                      <col width="" />
                      <col width="165" />
                      <col width="165" />
                      <col width="165" />
                      <col width="165" />
                      <col width="165" />
                      <col width="165" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>제조사/제품</th>
                        <th>스마트폰</th>
                        <th>GPS</th>
                        <th>RF차단기</th>
                        <th>라디오 안테나</th>
                        <th>RF하이패스</th>
                        <th>IR하이패스</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>루마Vertex</td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td>A사 최고가 라인</td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td>B사 최고가 라인</td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                        <td>
                          <img src={low} alt="" />
                        </td>
                        <td>
                          <img src={low} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td>C사 최고가 라인</td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td>D사 최고가 라인</td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                        <td>
                          <img src={low} alt="" />
                        </td>
                        <td>
                          <img src={low} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="lluma_table lluma_table_m info_table d-xl-none">
                    <colgroup>
                      <col width="" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th style={{ width: '18%', minWidth: '110px' }}>
                          제조사
                          <br />
                          /제품
                        </th>
                        <th>루마</th>
                        <th>A사</th>
                        <th>B사</th>
                        <th>C사</th>
                        <th>D사</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="font-weight-500">스마트폰</td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-500">GPS</td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-500">RF차단기</td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-500">
                          라디오
                          <br />
                          안테나
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-500">RF하이패스</td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-500">IR하이패스</td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                        <td>
                          <img src={high} alt="" />
                        </td>
                        <td>
                          <img src={mid} alt="" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="lluma_table_more w-100" style={{ marginTop: '40px' }}>
                    <p>
                      본 평가는 국제 공인 수치가 없어 고객들의 피드백을 기반으로 하여 작성되었습니다. 구체적인 단말기의
                      종류나 필름 시공 방식에 따라 차이가 존재할 수 있습니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Product
