import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import promo_1 from '../../image/promotion/promo_1.jpg'
import promo_2 from '../../image/promotion/promo_2.png'
import promo_icon_1 from '../../image/promotion/promo_icon_1.png'
import promo_icon_2 from '../../image/promotion/promo_icon_2.png'
import promo_icon_3 from '../../image/promotion/promo_icon_3.png'
import promo_icon_4 from '../../image/promotion/promo_icon_4.png'

import { sendGA } from '../../redux/actions'

class Promotion extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <div className="container banner_container">
          <div className="row">
            <div className="col-12 col-md-6 order-md-12 px-0">
              <img className="d-block w-100" src={promo_1} alt="" />
            </div>
            <div className="col-12 col-md-6 order-md-1">
              <div className="d-table w-100 h-100">
                <div className="d-table-cell align-middle">
                  <p className="headline">
                    측 후면 시공 시<br />
                    필름제거 무료
                  </p>
                  <p className="sub">
                    자동차썬팅필름의 핵심인 광학적 성능과 시인성,
                    <br />
                    그리고 고급감의 컬러까지 만족하는 썬팅필름의
                    <br />
                    융합 가치를 만들었습니다
                  </p>
                  <div className="quote_btn mt-30">
                    <Link
                      to={window.location.search === '?test=true' ? '/cars-tint?test=true' : '/'}
                      className="btn btn_red"
                      id="e2e_go_home"
                      onClick={() => sendGA({ category: 'Promotion', action: 'Start Quote' })}
                    >
                      온라인 견적내기
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container my-150">
          <table className="lluma_table promo_table mx-auto d-none d-xl-table">
            <colgroup>
              <col width="" />
              <col width="" />
              <col width="200" />
              <col width="200" />
              <col width="200" />
              <col width="200" />
            </colgroup>
            <thead>
              <tr>
                <th colSpan="2" />
                <th className="td_gray_1">전면/썬루프 제거비용</th>
                <th>할인 적용가</th>
                <th className="td_gray_1">측 후면유리 제거비용</th>
                <th>할인 적용가</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-left">국내차량</td>
                <td className="text-left">SEDAN/소형SUV</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td />
                <td className="text-left">SUV</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td />
                <td className="text-left">VAN</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="text-left">수입차량</td>
                <td className="text-left">SEDAN/소형SUV</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td />
                <td className="text-left">SUV</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td />
                <td className="text-left">VAN</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
            </tbody>
          </table>

          <table className="lluma_table lluma_table_m promo_table mx-auto d-xl-none">
            <colgroup>
              <col width="" />
              <col width="80" />
              <col width="" />
              <col width="80" />
            </colgroup>
            <thead>
              <tr>
                <th colSpan="4">국내차량 · SEDAN/소형SUV</th>
              </tr>
              <tr>
                <th className="td_gray_1">
                  전면/썬루프
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
                <th className="td_gray_1">
                  측 후면유리
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="4">국내차량 · SUV</th>
              </tr>
              <tr>
                <th className="td_gray_1">
                  전면/썬루프
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
                <th className="td_gray_1">
                  측 후면유리
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="4">국내차량 · VAN</th>
              </tr>
              <tr>
                <th className="td_gray_1">
                  전면/썬루프
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
                <th className="td_gray_1">
                  측 후면유리
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="4">수입차량 · SEDAN/소형SUV</th>
              </tr>
              <tr>
                <th className="td_gray_1">
                  전면/썬루프
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
                <th className="td_gray_1">
                  측 후면유리
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="4">수입차량 · SUV</th>
              </tr>
              <tr>
                <th className="td_gray_1">
                  전면/썬루프
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
                <th className="td_gray_1">
                  측 후면유리
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="4">수입차량 · VAN</th>
              </tr>
              <tr>
                <th className="td_gray_1">
                  전면/썬루프
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
                <th className="td_gray_1">
                  측 후면유리
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="container banner_container">
          <div className="row">
            <div className="col-12 col-md-6 order-md-12 px-0">
              <img className="d-block w-100" src={promo_2} alt="" style={{ maxWidth: '600px' }} />
            </div>
            <div className="col-12 col-md-6 order-md-1">
              <div className="d-table w-100 h-100">
                <div className="d-table-cell align-middle">
                  <p className="headline font-en">Why with us</p>
                  <p className="sub">
                    자동차 틴팅 필름의 핵심인 광학적 성능, 탁월한 선명도,
                    <br />열 차단, 고급스러운 컬러까지 모두 만족하는 완벽한 스펙
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container my-150">
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="promo_why">
                <img src={promo_icon_1} alt="" />
                <p>
                  글로벌 시장 1등의
                  <br />
                  검증된 브랜드
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="promo_why">
                <img src={promo_icon_2} alt="" />
                <p>
                  세계 최초로
                  <br />
                  듀얼 콤보 융합 적용
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="promo_why">
                <img src={promo_icon_3} alt="" />
                <p>
                  국제기준에 따른
                  <br />
                  압도적 태양열 차단
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="promo_why">
                <img src={promo_icon_4} alt="" />
                <p>
                  내부 난반사 없는
                  <br />
                  최고의 선명도
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Promotion
