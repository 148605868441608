import React, { Component } from 'react'
import { withRouter, Route, Redirect, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Layout from './components/Layouts'
import Package from './components/Package'
import Home3 from './components/Home_3'
import Product from './components/Product'
import Promotion from './components/Promotion'
import Tint from './components/Tint'
import Budget from './components/Budget'
import Shope from './components/Shope'
import Terms from './components/Terms'
import Chatbot from './components/Chatbot'

import store from './redux/store'
import { setHomePath, getAndSetParcipatedShops } from './redux/actions'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.gtag('config', 'UA-123334330-3', {
        custom_map: window.custom_map,
        page_location: window.location.href,
        page_path: this.props.location.pathname,
      })
    }
  }

  async componentDidMount() {
    await getAndSetParcipatedShops()
  }

  splitRoute() {
    const { homePath } = store.getState().home

    let random_number
    if (homePath === -1) {
      random_number = new Date().getMilliseconds() % 100
    } else {
      random_number = homePath
    }
    this.props.setHomePath(random_number)

    /*if (random_number < 33) {
      return <Redirect to="/cars-tint" />
    } else if (random_number < 66) {
      return <Redirect to="/cars-budget" />
    } else {
      return <Redirect to="/cars-packages" />
    }*/

    // In order to use VWO splitter
    return <Redirect to="/cars-tint" />
  }

  render() {
    return (
      <div className="App">
        <Layout>
          <Switch>
            <Route path="/" exact render={() => this.splitRoute()} />
            <Route path="/cars-packages" exact component={Home3} />
            <Route path="/cars-tint" exact component={Home3} />
            <Route path="/cars-budget" exact component={Home3} />
            <Route path="/cars-coupon" exact component={Home3} />
            <Route path="/packages" exact component={Package} />
            <Route path="/products" component={Product} />
            <Route path="/promotion" component={Promotion} />
            <Route path="/tint" exact component={Tint} />
            <Route path="/budget" exact component={Budget} />
            <Route path="/shops/:id" component={Shope} />
            <Route path="/consulting/start_consulting/:id" exact component={Shope} />
            <Route path="/consulting/complete_request/:id" exact component={Shope} />
            <Route path="/consulting/complete_request_offline/:id" exact component={Shope} />
            <Route path="/consulting/chat/:id" exact component={Shope} />
            <Route path="/terms" exact component={Terms} />
            <Route path="/chatbot" exact component={Chatbot} />
          </Switch>
        </Layout>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...state,
    homePath: state.homePath,
  }
}

export default withRouter(compose(connect(mapStateToProps, { setHomePath }))(App))
