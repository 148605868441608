import React from 'react'
import $ from 'jquery'
import { Link } from 'react-router-dom'

import logo_w160 from '../../image/logo/logo_w160.png'
import { sendGA } from '../../redux/actions'

const Header = props => (
  <header>
    <div className="navbar navbar-dark bg-black shadow-sm">
      <div className="container text-center">
        {window.location.pathname === '/l1' ? (
          <Link to="/l1" className="navbar-brand">
            <img src={logo_w160} alt="" />
          </Link>
        ) : (
          <Link to="/" className="navbar-brand">
            <img src={logo_w160} alt="" />
          </Link>
        )}
        <button
          className="navbar-toggler position-absolute"
          type="button"
          data-toggle="collapse"
          data-target="#navbarHeader"
          aria-controls="navbarHeader"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </div>
    <div className="collapse bg-black text-center" id="navbarHeader">
      <div className="container">
        <div className="row">
          <div className="col-12 py-4">
            <Link
              onClick={() => {
                $('.navbar-toggler').click()
                sendGA({ category: 'GNB', action: 'Go Home' })
              }}
              className="nav_link"
              to="/"
            >
              홈
            </Link>
            <Link
              onClick={() => {
                $('.navbar-toggler').click()
                sendGA({ category: 'GNB', action: 'Go Promotion' })
              }}
              className="nav_link"
              to="/promotion"
            >
              프로모션
            </Link>
            <Link
              onClick={() => {
                $('.navbar-toggler').click()
                sendGA({ category: 'GNB', action: 'Go LLumar Vertex' })
              }}
              className="nav_link"
              to="/products"
            >
              루마 버텍스
            </Link>
            <Link
              onClick={() => {
                $('.navbar-toggler').click()
                sendGA({ category: 'GNB', action: 'Go Quote' })
              }}
              className="nav_link"
              to="/quote"
            >
              시공 견적
            </Link>
          </div>
        </div>
      </div>
    </div>
  </header>
)

export default Header
