import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import { loadCarDB, getGroupByData, getfilteredData } from '../../data'

import { setHomePath, updateQuote, clearQuote } from '../../redux/actions'

import quote_1 from '../../image/quote/quote_1.png'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import tape from '../../image/home/tape.svg'
import '../../carSelection.scss'

import { sendGA } from '../../redux/actions'

class Home_3 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: {
        origin: getGroupByData(this.props.cars, 'origin'),
        maker: [],
        model: [],
        sunroof: ['없음', '썬루프', '파노라마'],
        origin_open: false,
        maker_open: false,
        model_open: false,
        sunroof_open: false,
      },
      popup_opend: true,
      day: 0,
      hours: 0,
      minutes: 0,
      second: 0,
    }
  }

  countDown() {
    const now = new Date().getTime()

    const lastDay = new Date('2020-01-01T00:00:00')
    // Close pop-up for 2020
    if (now > lastDay) {
      this.setState({
        popup_opend: false,
      })
    }

    const distance = lastDay - now

    if (distance < 0) {
      this.setState({ day: 0, hours: 0, minutes: 0, seconds: 0 })
    } else {
      const s = Math.floor((distance % (1000 * 60)) / 1000)
      const m = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const h = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const d = Math.floor((distance % (1000 * 60 * 60 * 24 * 31)) / (1000 * 60 * 60 * 24))

      this.setState({
        day: d,
        hours: h < 10 ? `0${h}` : h,
        minutes: m < 10 ? `0${m}` : m,
        seconds: s < 10 ? `0${s}` : s,
      })
    }
  }

  getAndSetCountedCar(min, max) {
    var randomNumber = Math.floor(Math.random() * (max - min + 1)) + min

    window.localStorage.setItem('count', randomNumber)
  }

  componentDidMount() {
    // Close pop-up for 2020
    const now = new Date().getTime()
    const lastDay = new Date('2020-01-01T00:00:00')
    if (now > lastDay) {
      this.setState({
        popup_opend: false,
      })
    } else {
      setInterval(() => this.countDown(), 1000)
    }

    this.props.clearQuote()
    this.getAndSetCountedCar(11, 95)

    window.scrollTo(0, 0)
    this.props.setHomePath(this.props.location.pathname)
  }

  onChange = (key, value, nextGroupKey) => {
    const options = this.state.options
    // const { sunroof } = this.props.car
    const newQuote = {
      ...this.props.car,
      [key]: value,
    }
    let newOpenState = {
      origin_open: false,
      maker_open: false,
      model_open: false,
      sunroof_open: false,
    }

    if (key === 'origin') {
      newQuote['maker'] = ''
      newQuote['model'] = ''
      newOpenState.maker_open = true
    }
    if (key === 'maker') {
      newQuote['model'] = ''
      newQuote['sunroof'] = ''
      newOpenState.model_open = true
    }
    if (key === 'model') {
      newOpenState.sunroof_open = true
    }

    if (nextGroupKey) {
      const filteredOptionData = getfilteredData(this.props.cars, {
        origin: newQuote['origin'],
        maker: newQuote['maker'],
        model: newQuote['model'],
        sunroof: newQuote['sunroof'],
        [key]: value,
      })
      options[nextGroupKey] = getGroupByData(filteredOptionData, nextGroupKey)
    }
    this.setState({
      ...newOpenState,
      options: options,
    })
    this.props.updateQuote({
      car: {
        ...newQuote,
      },
    })
  }

  onReset = () => {
    this.props.clearQuote()
  }

  onSearch = () => {
    const car = this.props.quote.quote.car
    const no_car_exist = !car.side_windows
    const url = document.location.href
    const path = url.split('-')
    if (!no_car_exist) {
      sendGA({ category: 'Home L3', action: 'Complete Car: L3' })
      sendGA({ category: 'Landing', action: 'Complete Car' })
      sendGA({ category: 'Landing', action: `Complete Car: ${document.location.pathname}` })
      path[1].indexOf('tint') !== -1 || path[1] === 'coupon'
        ? path[1].indexOf('test=true') !== -1
          ? this.props.history.push({
              pathname: '/tint',
              search: '?test=true',
            })
          : this.props.history.push({
              pathname: '/tint',
            })
        : this.props.history.push({
            pathname: '/packages',
          })
    }
  }

  toggleDropboxStatus(type) {
    const { state } = this
    const _type = !state[type]
    const _state = {
      maker_open: false,
      model_open: false,
      origin_open: false,
      sunroof_open: false,
      year_open: false,
    }
    _state[type] = _type
    this.setState(_state)
  }

  render() {
    const { car } = this.props
    const no_car_exist = !car.side_windows
    // const maker_target = this.props.car.origin ? { 'data-target': '#select_maker_modal' } : {}
    // const model_target = this.props.car.origin && this.props.car.maker ? { 'data-target': '#select_model_modal' } : {}

    const { popup_opend, day, hours, minutes, seconds } = this.state

    let dayChecker = true
    if (day === 0 && hours === 0 && minutes === 0) {
      dayChecker = false
    }
    const percentage = (day / 30) * 100

    return (
      <div>
        {popup_opend && (
          <div className="popup">
            <div className="popup_body">
              <div className="tape_section">
                <div className="title_text"> 연말 한정 이벤트 마감 임박!</div>
                <img className="tape_image" src={tape} alt="" />
              </div>

              <div className="title_section">
                <div className="content_text">
                  <span>12월 31일 이후에는</span> <br />
                  연말 이벤트 특가가 <span>돌아오지 않습니다.</span>
                </div>

                <div className="highligt_background" />
              </div>
              <div className="progress_bar">
                <CircularProgressbar
                  value={percentage}
                  counterClockwise={true}
                  strokeWidth={2}
                  styles={buildStyles({
                    // Rotation of path and trail, in number of turns (0-1)
                    rotation: 1,
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: 'round',
                    // Colors
                    pathColor: `#ff3455`,
                    transform: 'rotate(0.5turn)',

                    trailColor: '#eff1f4',
                  })}
                />
                <div className="inner_circle" />
              </div>
              <div className="time_section">
                <div className="time_day">
                  {dayChecker ? `${day}` : null}
                  {dayChecker && <span>일</span>}
                </div>

                <div className="time_left">{dayChecker ? `${hours} : ${minutes} : ${seconds}` : null}</div>
              </div>
              <div className="popup_footer">
                <div
                  className="button_later"
                  onClick={() => {
                    this.setState({
                      popup_opend: false,
                    })
                    sendGA({ category: 'Landing', action: 'Close Popup' })
                  }}
                >
                  나중에 보기
                </div>
                <div
                  className="button_check"
                  onClick={() => {
                    this.setState({
                      popup_opend: false,
                    })
                    sendGA({ category: 'Landing', action: 'Click Popup' })
                  }}
                >
                  실시간 특가 확인
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="container banner_container text-center">
          <h2 className="quote_title">
            안녕하세요? <br /> 현재 이용하고 계시는 차량을 선택해주세요.
          </h2>
          <img className="quote_title_img" src={quote_1} alt="" />
        </div>

        <div className="container quote_container">
          <div className="row">
            <div className="col-12 col-md-3">
              <div className="quote_select" name="quote_select">
                <div
                  id="e2e_quote_select_origin"
                  className={(this.state.origin_open ? 'quote_bottom_border ' : '') + 'quote_select_title '}
                  onClick={() => {
                    this.toggleDropboxStatus('origin_open')
                    this.onReset()
                  }}
                >
                  {car.origin || '01. 원산지를 선택해 주세요'}
                </div>
              </div>
              <div
                style={{ display: this.state.origin_open ? '' : 'none' }}
                className="carSelection_dropbox quote_select_title"
              >
                <div className="carSelection_dropbox_inner">
                  <div className="carSelection_dropbox_inner_box">
                    {this.state.options.origin.map((item, index) => {
                      return (
                        <div
                          key={index}
                          id={'e2e_carSelection_origin' + index}
                          onClick={() => this.onChange('origin', item, 'maker')}
                          className="carSelection_dropbox_item"
                        >
                          {item}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3">
              <div className="quote_select">
                <div
                  id="e2e_quote_select_make"
                  className={(this.state.maker_open ? 'quote_bottom_border ' : '') + 'quote_select_title '}
                  onClick={() => this.toggleDropboxStatus('maker_open')}
                >
                  {car.maker || '02. 제조사를 선택해 주세요'}
                </div>
              </div>
              <div style={{ display: this.state.maker_open ? '' : 'none' }} className="carSelection_dropbox">
                <div className="carSelection_dropbox_inner">
                  <div className="carSelection_dropbox_inner_box">
                    {this.state.options.maker.map((item, index) => {
                      return (
                        <div
                          key={index}
                          id={'e2e_carSelection_make' + index}
                          onClick={() => this.onChange('maker', item, 'model')}
                          className="carSelection_dropbox_item"
                        >
                          {item}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="quote_select">
                <div
                  className={(this.state.model_open ? 'quote_bottom_border ' : '') + 'quote_select_title '}
                  onClick={() => this.toggleDropboxStatus('model_open')}
                  id="e2e_quote_select_model"
                >
                  {car.model || '03. 모델명을 선택해 주세요'}
                </div>
              </div>
              <div style={{ display: this.state.model_open ? '' : 'none' }} className="carSelection_dropbox">
                <div className="carSelection_dropbox_inner">
                  <div className="carSelection_dropbox_inner_box">
                    {this.state.options.model.map((item, index) => {
                      return (
                        <div
                          key={index}
                          id={'e2e_carSelection_model' + index}
                          onClick={() => this.onChange('model', item)}
                          className="carSelection_dropbox_item"
                        >
                          {item}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="quote_select ">
                <div
                  className={(this.state.sunroof_open ? 'quote_bottom_border ' : '') + 'quote_select_title '}
                  onClick={() => this.toggleDropboxStatus('sunroof_open')}
                  id="e2e_quote_select_sunroof"
                >
                  {car.sunroof || '04. 썬루프를 선택해 주세요'}
                </div>
              </div>
              <div style={{ display: this.state.sunroof_open ? '' : 'none' }} className="carSelection_dropbox">
                <div className="carSelection_dropbox_inner">
                  <div className="carSelection_dropbox_inner_box">
                    {this.state.options.sunroof.map((item, index) => {
                      return (
                        <div
                          key={index}
                          id={'e2e_carSelection_sunroof' + index}
                          onClick={() => this.onChange('sunroof', item)}
                          className="carSelection_dropbox_item"
                        >
                          {item}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-150">
              <button id="e2e_btn_reset" className="btn btn_transparent float-left pl-0" onClick={this.onReset}>
                선택초기화
              </button>
              <button
                id="e2e_select_product"
                className="btn btn_red btn_lg float-right"
                style={{ opacity: no_car_exist ? 0.3 : 1 }}
                onClick={this.onSearch}
              >
                내 차에 맞는 제품 고르기
              </button>
            </div>
          </div>
        </div>

        <div className="container my-150">{/* <!-- 검색 결과물 위치 -->  */}</div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...state,
    quote: state.quote,
    car: state.quote.quote.car,
    cars: loadCarDB(),
  }
}
export default compose(
  firestoreConnect(),
  connect(mapStateToProps, {
    updateQuote,
    clearQuote,
    setHomePath,
  }),
)(Home_3)
