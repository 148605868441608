import React from 'react'
import { Link } from 'react-router-dom'

import logo_w160 from '../../image/logo/logo_w160.png'

import { sendGA } from '../../redux/actions'

const Header_2 = props => (
  <header>
    <div className="navbar navbar-dark bg-black shadow-sm">
      <div className="container text-right">
        {' '}
        {window.location.pathname === '/l0' ? (
          <Link to="/l0" className="navbar-brand_header_2">
            <img src={logo_w160} alt="" />
          </Link>
        ) : window.location.pathname === '/l1' ? (
          <Link to="/l1" className="navbar-brand_header_2">
            <img src={logo_w160} alt="" />
          </Link>
        ) : window.location.pathname === '/l2' ? (
          <Link to="/l2" className="navbar-brand_header_2">
            <img src={logo_w160} alt="" />
          </Link>
        ) : (
          <Link to="/" className="navbar-brand_header_2">
            <img src={logo_w160} alt="" />
          </Link>
        )}
        {
          <>
            {window.location.pathname === '/l0' ? (
              <Link
                onClick={() => {
                  sendGA({ category: 'GNB', action: 'Go Home' })
                }}
                className="nav_link_home"
                to="/l0"
              >
                홈
              </Link>
            ) : window.location.pathname === '/l1' ? (
              <Link
                onClick={() => {
                  sendGA({ category: 'GNB', action: 'Go Home' })
                }}
                className="nav_link_home"
                to="/l1"
              >
                홈
              </Link>
            ) : window.location.pathname === '/l2' ? (
              <Link
                onClick={() => {
                  sendGA({ category: 'GNB', action: 'Go Home' })
                }}
                className="nav_link_home"
                to="/l2"
              >
                홈
              </Link>
            ) : (
              <Link
                onClick={() => {
                  sendGA({ category: 'GNB', action: 'Go Home' })
                }}
                id="e2e_nav_link_home"
                className="nav_link_home"
                to="/"
              >
                홈
              </Link>
            )}

            <Link
              onClick={() => {
                sendGA({ category: 'GNB', action: 'Go Promotion' })
              }}
              id="e2e_nav_link_promotion"
              className="nav_link_other"
              to={window.location.search === '?test=true' ? '/promotion?test=true' : '/promotion'}
            >
              프로모션
            </Link>
            <Link
              onClick={() => {
                sendGA({ category: 'GNB', action: 'Go LLumar Vertex' })
              }}
              id="e2e_nav_link_products"
              className="nav_link_other"
              to={window.location.search === '?test=true' ? '/products?test=true' : '/products'}
            >
              루마 버텍스
            </Link>
          </>
        }
      </div>
    </div>
  </header>
)

export default Header_2
