import React, { Component } from 'react'
import ReactSlider from 'react-slider'
import Cleave from 'cleave.js/react'
import { loadProductsDB } from '../../data'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import { saveQuote, sendGA } from '../../redux/actions'

class Budget extends Component {
  constructor(props) {
    super(props)

    const quoteId = props.match.params.id
    this.state = {
      quoteId: quoteId,
      saved: false,
      value: 100,
    }
    this.onConfirm = this.onConfirm.bind(this)
  }

  onConfirm() {
    this.props.quote.quote = {
      ...this.props.quote.quote,
      budget: {
        value: this.state.value,
        isSelected: true,
      },
    }
    this.props.saveQuote(this.props.quote)

    this.setState({
      saved: true,
    })
    sendGA({ category: 'Quote', action: 'Complete Budget Selection' })
  }

  componentDidMount() {
    if (!this.state.quoteId && !this.props.quote.quote.car.side_windows) {
      this.props.history.push({
        pathname: '/cars-budget',
      })
    }
    window.scrollTo(0, 0)
  }

  componentDidUpdate() {
    if (this.props.quote.quote_id && this.state.saved) {
      this.props.history.push({
        pathname: `/shops/${this.props.quote.quote_id}`,
      })
    }
  }

  render() {
    return (
      <div>
        <div>
          <div className="container budget_container">
            <div className="budget_title">
              시공에 필요한 예산 범위를 입력해주세요.
              <br />
              고객님의 예산에 맞는 제품을 추천해드리겠습니다!
            </div>

            <div className="budget_component">
              <div className="budget_price_box">
                <div className="budget_price_text"> 예산 </div>
                <div className="budget_price_value">
                  <Cleave
                    options={{
                      numeral: true,
                      numeralThousandsGroupStyle: 'thousand',
                    }}
                    value={this.state.value}
                    onInit={cleave => {
                      this.budget_price = cleave
                    }}
                    readOnly
                    className="budget_price_input"
                  />
                  만원대
                </div>
              </div>
              <button className="btn btn_red btn_lg" onClick={this.onConfirm}>
                나에게 맞는 제품 추천 받기
              </button>
            </div>

            <div className="budget_range_bar">
              <ReactSlider
                className="horizontal-slider"
                defaultValue={this.state.value}
                onChange={value => {
                  this.setState({
                    value: value,
                  })
                  this.budget_price.setRawValue(value)
                }}
                min={10}
                max={200}
                step={10}
                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
              />
            </div>

            <div className="budget_caption_box">
              <div className="budget_price_caption">10 만원</div>
              <div className="budget_price_caption">200 만원</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const products = loadProductsDB({
    type: 'vertex',
  })

  return {
    ...state,
    products: products,
    quote: state.quote,
    quotes: state.firestore.data && state.firestore.data.quotes,
  }
}

export default compose(
  firestoreConnect(props => {
    if (!props.match.params.id) return []
    return [{ collection: 'quotes', doc: props.match.params.id }]
  }),
  connect(
    mapStateToProps,
    {
      saveQuote,
    },
  ),
)(Budget)
