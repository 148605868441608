import React from 'react'
import Window from './Window'
import Line from './Line'
import { sendGA } from '../../redux/actions'

const Car = ({ Info, Car, Products, ProductStatus, onUpdate, onSubmit }) => {
  const OnSelectAll = () => {
    onUpdate({
      product_status: Array(9).fill({
        selected: true,
        is_open: false,
      }),
    })
  }

  const OnSelectReset = () => {
    onUpdate({
      product_status: Array(9).fill({
        selected: false,
        is_open: false,
      }),
      info: {},
    })
  }

  const OnSelectAuto = () => {
    let productStatus = ProductStatus.slice()

    productStatus = Array(9).fill({
      selected: true,
      is_open: false,
      lineup: '버텍스 700',
    })
    // if sunroof was not selected
    if (Car.sunroof === '없음') {
      productStatus[8] = {
        selected: false,
        is_open: false,
        lineup: '',
      }
    }

    onUpdate({
      product_status: productStatus,
      info: {
        sum_price: 1,
      },
    })
  }

  const lineComponent = (
    <Line Info={Info} Car={Car} Products={Products} ProductStatus={ProductStatus} OnUpdate={onUpdate} />
  )
  const windowComponent = <Window Info={Info} Car={Car} ProductStatus={ProductStatus} OnUpdate={onUpdate} />

  const is_okay_proceed = !Info.sum_price || parseInt(Info.sum_price) === 0 ? false : true
  const is_auto_button_clicked = Info.sum_price === 1 ? true : false
  const count = window.localStorage.getItem('count')

  return (
    <div>
      <div className="row ">
        <div className="col-12 col-lg-6 tint_page">
          <div className="tint_title">1. 차량창문을 선택해주세요</div>
          {windowComponent}
          <div className="tint_select_btn clearfix hidden-xs">
            <button
              style={{ width: '100%' }}
              id="e2e_auto_select_button"
              className="auto_select_button"
              name="auto_select"
              onClick={() => {
                OnSelectAuto()
                sendGA({ category: 'Quote', action: 'Click Auto Selection' })
              }}
            >
              가장 많이 시공하는 제품 자동선택
            </button>

            <button className="btn btn_black btn_lg" id="e2e_all_select" name="all_select" onClick={OnSelectAll}>
              전체선택
            </button>
            <button className="btn btn_transparent_border" id="e2e_all_delete" onClick={OnSelectReset}>
              선택초기화
            </button>
          </div>
          <div className="tint_select_btn clearfix hidden-sm">
            <a
              style={{ width: '100%' }}
              className="btn btn_light_red btn_lg auto_select_button"
              name="auto_select"
              href="#next_step"
              onClick={() => {
                OnSelectAuto()
                sendGA({ category: 'Quote', action: 'Click Auto Selection' })
              }}
            >
              가장 많이 시공하는 제품 자동선택
            </a>
            {is_auto_button_clicked && ProductStatus[8].selected === false ? (
              <div className="balloon_tint">
                이번 달에 &nbsp;<span style={{ fontWeight: 'bold' }}>고객님 차량 {count}대</span>가 시공했어요!
              </div>
            ) : null}
            <a className="btn btn_black btn_lg" href="#next_step" style={{ width: '100%' }}>
              다음단계로
            </a>
          </div>
        </div>
        <div className="col-12 col-lg-6 tint_page" id="next_step">
          <div className="tint_title">2. 제품라인을 선택해주세요</div>
          {lineComponent}

          <div className="tint_select_btn clearfix hidden-xs" style={{ maxWidth: '500px', marginBottom: 0 }}>
            {is_auto_button_clicked && ProductStatus[8].selected === false ? (
              <div className="balloon_tint">
                이번 달에&nbsp;
                <span style={{ fontWeight: 'bold' }}>고객님 차량 {count}대</span>가 시공했어요!
              </div>
            ) : null}

            <button
              className="btn btn_red btn_lg btn_position"
              name="quote_ok"
              id="e2e_check_price"
              href=""
              onClick={() => {
                if (!is_okay_proceed) return
                onSubmit()
              }}
              style={{ opacity: is_okay_proceed ? 1 : 0.3 }}
            >
              견적 가격 확인
            </button>
          </div>
        </div>
      </div>

      <div className="tint_select_btn clearfix hidden-sm tint_sum_fixed" style={{ maxWidth: '500px', marginBottom: 0 }}>
        <button
          className="btn btn_red btn_lg btn_position_mobile"
          href=""
          name="quote_ok"
          onClick={() => {
            if (!is_okay_proceed) return
            onSubmit()
          }}
          style={{ opacity: is_okay_proceed ? 1 : 0.3 }}
        >
          견적 가격 확인
        </button>
      </div>
    </div>
  )
}

export default Car
