import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import { updateQuote, saveQuote, sendGA } from '../../redux/actions'

import sale_badge from '../../../src/image/quote/sale_badge.svg'
import '../../carSelection.scss'

import packages from '../../data/info_packages.js'

class Package extends Component {
  constructor(props) {
    super(props)
    const quoteId = props.match.params.id
    this.state = {
      quoteId: quoteId,
      saved: false,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }

  countDown() {
    const now = new Date().getTime()

    const countDownDate = now + 35000000 // 10시간

    if (!window.localStorage.getItem('time') && window.localStorage.getItem('time') !== undefined) {
      window.localStorage.setItem('time', countDownDate)
    }
    const distance = window.localStorage.getItem('time') - now

    if (distance < 0) {
      this.setState({ hours: 0, minutes: 0, seconds: 0 })
    } else {
      const s = Math.floor((distance % (1000 * 60)) / 1000)
      const m = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const h = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      this.setState({ hours: h < 10 ? `0${h}` : h, minutes: m < 10 ? `0${m}` : m, seconds: s < 10 ? `0${s}` : s })
    }
  }
  componentDidMount() {
    setInterval(() => this.countDown(), 1000)
    window.scrollTo(0, 0)

    this.setState({
      isMobile: this.isMobile(),
    })
  }

  isMobile() {
    var userAgent = navigator.userAgent

    if (
      userAgent.match(
        /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i,
      ) != null ||
      userAgent.match(/LG|SAMSUNG|Samsung/) != null
    ) {
      return true
    } else {
      return false
    }
  }

  async onPackageClick(_package) {
    this.props.quote.quote = {
      ...this.props.quote.quote,
      packages: _package,
    }

    this.props.updateQuote({
      _package,
    })

    this.props.saveQuote(this.props.quote)

    this.setState({
      saved: true,
    })
  }

  componentDidUpdate() {
    if (this.props.quote.quote_id && this.state.saved) {
      this.props.history.push({
        pathname: `/shops/${this.props.quote.quote_id}`,
      })
    }
  }

  render() {
    const { isMobile } = this.state

    return (
      <div>
        <div className="container banner_container text-center">
          <h2 className="package_title">12월 썬팅 패키지 상품리스트</h2>
          {!isMobile ? (
            <h3 className="package_subtitle">진행중인 이벤트 상품들은 재고 소진 시에 조기 품절될 수 있습니다!</h3>
          ) : (
            <h3 className="package_subtitle">
              진행중인 이벤트 상품들은 재고 소진 시에 <br />
              조기 품절될 수 있습니다!
            </h3>
          )}
        </div>

        <div className="container_package">
          <div className="row">
            <div className="area_width_control">
              {packages.map((_package, index) => {
                return (
                  <div key={index}>
                    {!isMobile ? (
                      <div>
                        <div>
                          {_package.special ? (
                            <div>
                              <img className="sale_badge" src={sale_badge} alt="" />
                              <div className="sale_badge_text">기간 한정 특가</div>
                            </div>
                          ) : null}
                          <div className="package_area">
                            <div>
                              <img className="package_img" src={_package.image[0]} alt="" />
                            </div>
                            <div className="package_info_area">
                              <div style={{ display: 'flex' }}>
                                <div>
                                  <div className="product_package">{_package.product_package}</div>
                                  <div className="product_name">전면창 ・ {_package.product_name[0]}</div>
                                  <div className="product_name">측후면 ・ {_package.product_name[1]}</div>

                                  <div className="product_price">한국/세단 기준 {_package.product_price} 원</div>
                                  <div className="sale_price_info_text">할인 적용가는 매장별로 상이합니다.</div>
                                </div>

                                <div>
                                  {_package.special ? (
                                    <div>
                                      <div className="end_date">할인 마감까지</div>
                                      <div className="countdown_clock">
                                        {this.state.hours} : {this.state.minutes} : {this.state.seconds}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              {_package.special ? (
                                <div className="balloon">
                                  현재까지 <span className="num_of_packages">{_package.num}개</span> 남음
                                </div>
                              ) : null}

                              <div className="project_button_area">
                                <div
                                  className="seacrh_shop_button"
                                  onClick={() => {
                                    this.onPackageClick(_package)
                                    sendGA({ category: 'Package', action: 'Click Finding Shops' })
                                  }}
                                >
                                  시공점 찾아보기
                                </div>
                                <div
                                  className={!_package.special ? 'package_try_chat' : 'package_try_chat_special'} // true 면 classname을 변경해서 버튼을 다르게 바꿔주기
                                  onClick={() => {
                                    this.onPackageClick(_package)
                                    !_package.special
                                      ? sendGA({ category: 'Package', action: 'Click Realtime Quote' })
                                      : sendGA({ category: 'Package', action: 'Click Special Price' })
                                  }}
                                >
                                  {_package.button_name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div>
                          {_package.special ? (
                            <div>
                              <img className="sale_badge" src={sale_badge} alt="" />
                              <div className="sale_badge_text">기간 한정 특가</div>
                            </div>
                          ) : null}
                          <div className="package_area">
                            <div>
                              <img className="package_img" src={_package.image[1]} alt="" />
                            </div>
                            <div className="package_info_area">
                              <div style={{ display: 'flex' }}>
                                <div>
                                  <div className="product_package">{_package.product_package}</div>
                                  <div className="product_name">전면창 ・ {_package.product_name[0]}</div>
                                  <div className="product_name">측후면 ・ {_package.product_name[1]}</div>

                                  <div className="product_price">한국/세단 기준 {_package.product_price} 원</div>
                                  <div className="sale_price_info_text">할인 적용가는 매장별로 상이합니다.</div>
                                </div>

                                <div>
                                  {_package.special ? (
                                    <div>
                                      <div className="end_date">할인 마감까지</div>
                                      <div className="countdown_clock">
                                        {this.state.hours} : {this.state.minutes} : {this.state.seconds}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              {_package.special ? (
                                <div className="balloon">
                                  현재까지 <span className="num_of_packages">{_package.num}개</span> 남음
                                </div>
                              ) : null}
                            </div>
                            <div className="project_button_area">
                              <div
                                className={!_package.special ? 'package_try_chat' : 'package_try_chat_special'} // true 면 classname을 변경해서 버튼을 다르게 바꿔주기
                                onClick={() => {
                                  this.onPackageClick(_package)
                                  !_package.special
                                    ? sendGA({ category: 'Package', action: 'Click Realtime Quote' })
                                    : sendGA({ category: 'Package', action: 'Click Special Price' })
                                }}
                              >
                                {_package.button_name}
                              </div>
                              <div
                                className="seacrh_shop_button"
                                onClick={() => {
                                  this.onPackageClick(_package)
                                  sendGA({ category: 'Package', action: 'Click Finding Shops' })
                                }}
                              >
                                시공점 찾아보기
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...state,
  }
}

export default compose(
  firestoreConnect(),
  connect(mapStateToProps, {
    saveQuote,
    updateQuote,
  }),
)(Package)
