import React from 'react'
import PropTypes from 'prop-types'

import low from '../../image/product/low.png'

import guide_d from '../../image/tint/d_product_guide.png'
import guide_m from '../../image/tint/m_product_guide.png'

const Guide = ({ title }) => (
  <div className="tint_guide_button_pos">
    <div
      id="e2e_btn_product_guide"
      className="btn tint_film_guide_btn shadow-sm"
      data-toggle="modal"
      data-target="#product_guide"
    >
      <i className="fa fa-question-circle"></i> {title}
    </div>
    <div className="modal fade" id="product_guide" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div id="e2e_product_guide_close" className="modal_close" data-dismiss="modal">
            <img src={low} alt="" widht="20" />
          </div>
          <div className="modal-body">
            <img src={guide_d} alt="" className="hidden-sm-down" />
            <img src={guide_m} alt="" className="hidden-md-up" />
          </div>
        </div>
      </div>
    </div>
  </div>
)

Guide.propTypes = {
  title: PropTypes.string,
}

export default Guide
