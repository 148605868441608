export default [
  //product_name: "전면창", "측후면"
  {
    product_package: '버텍스 1100 X 900',
    product_name: ['버텍스 1100', '버텍스 900'],
    package_type: '프리미엄',
    product_price: '2,260,000',
    image: [require('../image/quote/vertex-11002x.jpg'), require('../image/quote/vertex-11002x-m.jpg')],
    special: false,
    button_name: '실시간 견적',
  },
  {
    product_package: '버텍스 900 X 700',
    product_name: ['버텍스 900', '버텍스 700'],
    package_type: '프리미엄',
    product_price: '1,000,000',
    image: [require('../image/quote/vertex-9002x.jpg'), require('../image/quote/vertex-9002x-m.jpg')],
    special: true,
    button_name: '실시간 특가 확인',
    num: 8,
  },
  {
    product_package: '버텍스 700',
    product_name: ['버텍스 700', '버텍스 700'],
    package_type: '프리미엄',
    product_price: '800,000',
    image: [require('../image/quote/vertex-7002x.jpg'), require('../image/quote/vertex-7002x-m.jpg')],
    special: true,
    button_name: '실시간 특가 확인',
    num: 6,
  },
  {
    product_package: '버텍스 650',
    product_name: ['버텍스 650', '버텍스 650'],
    package_type: '프리미엄',
    product_price: '650,000',
    image: [require('../image/quote/vertex-6502x.jpg'), require('../image/quote/vertex-6502x-m.jpg')],
    special: false,
    button_name: '실시간 견적',
  },
  {
    product_package: '버텍스 500',
    product_name: ['버텍스 500', '버텍스 500'],
    package_type: '프리미엄',
    product_price: '500,000',
    image: [require('../image/quote/vertex-5002x.jpg'), require('../image/quote/vertex-5002x-m.jpg')],
    special: false,
    button_name: '실시간 견적',
  },
  {
    product_package: '래티튜드',
    product_name: ['래티튜드', '래티튜드'],
    package_type: '최고급',
    product_price: '800,000',
    image: [require('../image/quote/latitude2x.jpg'), require('../image/quote/latitude2x-m.jpg')],
    special: true,
    button_name: '실시간 특가 확인',
    num: 4,
  },
  {
    product_package: '티앤티 (TNT)',
    product_name: ['TNT', 'TNT'],
    package_type: '고급',
    product_price: '800,000',
    image: [require('../image/quote/tnt2x.jpg'), require('../image/quote/tnt2x-m.jpg')],
    special: false,
    button_name: '실시간 견적',
  },
  {
    product_package: '파워',
    product_name: ['파워', '파워'],
    package_type: '고급',
    product_price: '800,000',
    image: [require('../image/quote/power2x.jpg'), require('../image/quote/power2x-m.jpg')],
    special: false,
    button_name: '실시간 견적',
  },
]
